import React, { Component, useEffect, useState, useRef } from "react";
import { useQuery, NetworkStatus } from "@apollo/client";
import { Container, Row, Col } from "reactstrap";

function Main(props) {
  return (
    <div>
    {props.children}
    </div>
  );
}

export default Main;
