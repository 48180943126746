import React, { Component, useEffect, useState, useRef } from "react";
import { useQuery } from "@apollo/client";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import qs from 'qs';
import Main from "./components/index.js";
import UnderhoodMain from "./components/Underhood";
import UnderhoodSub from "./components/Underhood/UnderhoodSub";

import { Container, Row, Col, Button } from "reactstrap";
require("dotenv").config();

function App(props) {
  const [pass, setPass] = useState("");
  
  useEffect(async()  =>  {
    let params = window.location.search;
        params = qs.parse(params);
    setPass(`#${params['?pass']}`);
    let local  = localStorage.getItem('valid');
    if(!local){
      handleValid();
    }
  },[pass])

  const handleChange = e => {
    setPass(e.target.value);
  };

  const handleValid = e => {
    if (
      pass === process.env.REACT_APP_PASS_VALID
    ) {
      localStorage.setItem("site", 'real-dog');
      localStorage.setItem("valid", pass);
      window.location.reload();
    } else if(pass === process.env.REACT_APP_PASS_VALID_BV) {
      localStorage.setItem("site", 'beast-village');
      localStorage.setItem("valid", pass);
      window.location.reload();
    } else {
      console.log("Wrong password");
    }
  };
  return (
    <div className="App">
      <Router>
        <Main>
          {localStorage.getItem("valid") ===
            process.env.REACT_APP_PASS_VALID || localStorage.getItem("valid") ===
            process.env.REACT_APP_PASS_VALID_BV ? (
              <Switch>
                <Route exact path="/" render={props => <UnderhoodMain {...props} />} />
                <Route
                  exact
                  path="/:sefurl"
                  render={props => <UnderhoodSub {...props} />}
                />
                <Redirect to="/" />
              </Switch>
            ) : (
              <div className="loginpage">
                <div className="loginbox">
                  <img src="https://realdog-s3.s3-us-west-1.amazonaws.com/underhood/underhood-logo-trim.png" />
                  <p>
                    This portal is intended for our teams and investors. With access, you will see what's under the hood of the organization.
                  </p>
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                  />
                  <Button className="w-100 h4 btn" onClick={handleValid}>
                    {" "}
                    Submit{" "}
                  </Button>
                </div>
              </div>
            )}
        </Main>
      </Router>
    </div>
  );
}

export default App;
