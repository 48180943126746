import React, { Component, useEffect, useState, useRef } from "react";
import { useQuery, NetworkStatus } from "@apollo/client";
import { GET_UNDERHOOD_LIST, GET_UNDERHOOD } from "./../../queries";
import Dante from "Dante2";
import { Container, Row, Col } from "reactstrap";
import Sidebar from "./Sidebar";
import SidebarMenu from "../sidebarMenu";

function UnderhoodSub(props) {

  const { sefurl } = props.match.params;

  const isInitialMount = useRef(true);
  const [menuOpen, setmenuOpen] = useState(false);

  const { loading, error, data } = useQuery(GET_UNDERHOOD, {
    variables: { main: false, site: localStorage.getItem("site"), sefurl: sefurl },
    fetchPolicy: "no-cache"
  });

  const handleStateChange = state => {
    setmenuOpen(state.isOpen);
  };

  const toggleMenu = () => {
    setmenuOpen(!menuOpen);
  };


  useEffect(() => {
    const onCompleted = data => {
      isInitialMount.current = false;
    };
    const onError = error => {
      /* magic */
    };
    if (isInitialMount.current) {
      if (onCompleted || onError) {
        if (onCompleted && !loading && !error) {
          onCompleted(data);
        } else if (onError && !loading && error) {
          onError(error);
        }
      }
    }
  }, [loading, data, error]);

  if (loading) return <div>Loading</div>;

  if (error) return <div>Error</div>;



  return (
    <div>
      <div id="outer-container">
        <SidebarMenu isOpen={menuOpen} handleStateChange={handleStateChange} dataList={data.underhoodMain.text} />
        <Container>
          <div className="bar__module">
            <a
              onClick={toggleMenu}
              className="btn btn--sm type--uppercase hamb"
              target="_blank"
              // onclick="openNav()"
            >
              <div class="hambmenu">
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
              </div>
            </a>
          </div>
          <Row>
            <Col md={9}><div className='maincontent'><Dante content={JSON.parse(data.underhoodMain.text)} read_only={true} /></div></Col>
            <Col md={3}>
              <Sidebar dataList={data.underhoodMain.text}/>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default UnderhoodSub;
