import React, { Component, useEffect, useState, useRef } from "react";
import { useQuery, NetworkStatus } from "@apollo/client";
import { Container, Row, Col, Button } from "reactstrap";
import { GET_UNDERHOOD_LIST } from "../../queries";
import {
  Redirect,
  withRouter
} from "react-router-dom";
function Sidebar(props) {
  const { loadingSub, subscription, join, left }  = props;
  const isInitialMount = useRef(true);
  const [ showStatics, setShowStatics ] = useState(false);

  const { loading, error, data } = useQuery(GET_UNDERHOOD_LIST, {
    variables: {site: localStorage.getItem("site")}
  });

  const selectNow = event => {
    const scroll = document.querySelector(`[data-offset-key="${event.target.value}-0-0"]`);
    scroll.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(()  =>  {
    const site = localStorage.getItem("site");
    setShowStatics(site === 'real-dog' ? true : false);
  })

  const logout = () => {
    localStorage.setItem("valid", '');
    window.location.href = '/';
  }


  if (loading ) return <div>Loading</div>;

  if (error) return <div>Error</div>;

  return (
    <div className='sidebar'>
      <div>
        <h2>CONTENTS</h2>
        {JSON.parse(props.dataList).blocks.map(data => {
          return (
            <div key={data.key}>
              {data.type === "header-two" ? (
                <Button  onClick={selectNow} value={data.key}>
                  {data.text}
                </Button>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
      <h2>SECTIONS</h2>
      {data.underhoodsUnique.map(underhood => (
        <a key={underhood.id} href={underhood.sefurl}>
          {underhood.title}
        </a>
      ))}
      

      <div>
        <Button className='logoutbtn' onClick={logout}>Logout</Button>
      </div>
    </div>
  );
}

export default Sidebar;
