import { gql } from "@apollo/client";

export const GET_UNDERHOOD_LIST = gql`
  query($site: String) {
    underhoodsUnique(site: $site) {
      id
      title
      text
      sefurl
      status
      main
      createdAt
      updatedAt
      site
      order
    }
  }
`;

export const GET_UNDERHOOD = gql`
  query($main: Boolean, $site: String, $sefurl: String) {
    underhoodMain(main: $main, site: $site, sefurl: $sefurl) {
        id
        title
        text
      }
    }
`;

export const GET_SUBSCRIPTION_STATUS = gql`
  query{
    subscriptionStatus{
      active
    }
  }
`;

export const GET_HOW_MUCH_JOIN = gql`
  query{
    howMuchToday{
      count
    }
  }
`;

export const GET_HOW_MUCH_LEFT = gql`
  query{
    howMuchLeft{
      count
    }
  }
`;
