import React, { Component, useEffect, useState, useRef } from "react";
import { useQuery, NetworkStatus } from "@apollo/client";
import { GET_UNDERHOOD_LIST, GET_UNDERHOOD, GET_SUBSCRIPTION_STATUS, GET_HOW_MUCH_JOIN, GET_HOW_MUCH_LEFT } from "./../../queries";
import Dante from "Dante2";
import { Container, Row, Col, Button } from "reactstrap";
import Sidebar from "./Sidebar";
import SidebarMenu from "../sidebarMenu";


function UnderhoodMain(props) {
  const isInitialMount = useRef(true);
  const [menuOpen, setmenuOpen] = useState(false);

  const { loading, error, data } = useQuery(GET_UNDERHOOD, {
    variables: { main: true, site: localStorage.getItem("site"), sefurl: "/" },
    fetchPolicy: "no-cache"
  });

  const { loading: loadingSub, error: errorSub, data: subscription } = useQuery(GET_SUBSCRIPTION_STATUS);
  const { loading: loadingJoin, error: errorJoin, data: join } = useQuery(GET_HOW_MUCH_JOIN);
  const { loading: loadingLeft, error: errorLeft, data: left } = useQuery(GET_HOW_MUCH_LEFT);

  const handleStateChange = state => {
    setmenuOpen(state.isOpen);
  };

  const toggleMenu = () => {
    setmenuOpen(!menuOpen);
  };

  useEffect(() => {
    const onCompleted = data => {
      isInitialMount.current = false;
    };
    const onError = error => {
      /* magic */
    };
    if (isInitialMount.current) {
      if (onCompleted || onError) {
        if (onCompleted && !loading && !error) {
          onCompleted(data);
        } else if (onError && !loading && error) {
          onError(error);
        }
      }
    }
  }, [loading, data, error]);

  if (loading) return <div>Loading</div>;

  if (error) return <div>Error</div>;

  return (
    <div>
      <div id="outer-container">
        <SidebarMenu isOpen={menuOpen} handleStateChange={handleStateChange} dataList={data.underhoodMain.text} />
        <Container>
          <div className="bar__module">
            <a
              onClick={toggleMenu}
              className="btn btn--sm type--uppercase hamb"
              target="_blank"
              // onclick="openNav()"
            >
              <div className="hambmenu">
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
            </a>
          </div>
          <Row>
            <Col md={9} sm={12} xs={12}>
              <div className="maincontent">
                <Dante
                  content={JSON.parse(data.underhoodMain.text)}
                  read_only={true}
                />
              </div>
            </Col>
            <Col md={3} xs={12}>
              <Sidebar
                dataList={data.underhoodMain.text}
                loadingSub={loadingSub}
                subscription={subscription ? subscription.subscriptionStatus : ''}
                join={join}
                left={left}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default UnderhoodMain;
