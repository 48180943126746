import React, { Component } from "react";
import PropTypes from "prop-types";
import { push as Menu } from "react-burger-menu";
import Sidebar from "./Underhood//Sidebar";

export default class SidebarMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropMenu: ""
    };
    this.selectDropMenu = this.selectDropMenu.bind(this);
  }

  selectDropMenu(menu) {
    const { dropMenu } = this.state;
    this.setState({ dropMenu: dropMenu === menu ? "" : menu });
  }

  render() {
    const { isOpen, handleStateChange } = this.props;
    const { dropMenu } = this.state;
    return (
      <div >
        <Menu
          customBurgerIcon={false}
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
          isOpen={isOpen}
          bodyClassName={"animate-burger"}
          onStateChange={state => handleStateChange(state)}
        >
          <div
            id="sidenav"
            className="nav-sidebar-column bg--dark sidenav_block"
          >
            <hr />
            <Sidebar dataList={this.props.dataList}/>
          </div>
        </Menu>
      </div>
    );
  }
}

// export default ({ isOpen, dropMenu, selectDropMenu, handleStateChange }) => (
//
// );
