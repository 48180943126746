import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { gql } from '@apollo/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
require("dotenv").config();

const client = new ApolloClient({
  uri: process.env.REACT_APP_HTTP_URI,
  cache: new InMemoryCache()
});


  ReactDOM.render(
    <React.Fragment>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.Fragment>,
    document.getElementById("root")
  );
